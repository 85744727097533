<template>
  <div id="contacto">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 text-start leaves-cont">
          <img src="../assets/svg/contact-leaves-1.svg" alt="">
        </div>
        <div class="col-lg-4 text-center contact-text">
          <h1 class="staat-2">¡Contáctanos!</h1>
          <p class="text-s">Si aún tienes dudas o quieres agendar una reunión con nosotros, déjanos un mensaje.</p>
          <iframe data-tally-src="https://tally.so/embed/3ELA5B?hideTitle=1&transparentBackground=1&dynamicHeight=1" loading="lazy" width="100%" height="276" frameborder="0" marginheight="0" marginwidth="0" title="Contacto Landingpage"></iframe>
        </div>
        <div class="col-lg-4 text-end leaves-cont">
          <img src="../assets/svg/contact-leaves-2.svg" alt="">
        </div>
      </div>
    </div>
    <div class="datos-contacto container-fluid">
      <div class="container">
        <div class="row text-center">
          <div class="col-lg-4 col-12 card card-contacto text-center">
            <div class="d-flex">
              <img src="../assets/icons/mail-icon.svg" alt="" class="mail-icon">
            </div>
            <h3 class="lato-4 white-font">
              Correo
            </h3>
            <p class="text-xs"><a href="mailto:hola@lahiguera.org">hola@lahiguera.org</a></p>
          </div>
          <div class="col-lg-4 col-12 card card-contacto text-center">
            <div class="d-flex">
              <img src="../assets/icons/phone-icon.svg" alt="" class="phone-icon">
            </div>
            <h3 class="lato-4 white-font">
              Teléfono / Whatsapp
            </h3>
            <p class="text-xs"><a href="tel:+56973487654">+569 7348 7654</a></p>
          </div>
          <div class="col-lg-4 col-12 card card-contacto text-center">
            <div class="d-flex">
              <img src="../assets/icons/location-icon.svg" alt="" class="location-icon">
            </div>
            <h3 class="lato-4 white-font">
              Ubicación
            </h3>
            <p class="text-xs">Santiago, Región Metropolitana</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: 'ContactView',
  components: {

  },
  mounted() {
    window.Tally.loadEmbeds()
  }
}
</script>
