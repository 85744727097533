<template>
  <div 
    class="card"  
    @mouseover="handleMouseOver" 
    @mouseleave="handleMouseLeave"
    >
    <div class="card-body">
      <img :src="imagePath" alt="">
      <h5 class="card-title staat-4">{{CardTitle}}</h5>
      <p class="card-text text-xxs">{{ CardText }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardServicio',
  props: {
    cardImgRoute : {
      type: String,
      default: 'img.png'
    },
    cardImgHoverRoute: {
      type: String,
      default: 'img-hover.png',
    },
    CardTitle: {
      type: String,
      default: 'Titulo Card'
    },
    CardText: {
      type: String,
      default: 'Lorem Impsum'
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    imagePath() {
      return this.isHovered
        ? require(`@/assets/svg/${this.cardImgHoverRoute}`)
        : require(`@/assets/svg/${this.cardImgRoute}`);
      /*try {
        return require(`@/assets/svg/${this.cardImgRoute}`); // Asegúrate de que la carpeta y el nombre del archivo estén correctos
      } catch (e) {
        console.error(`El icono ${this.cardImgRoute} no se encontró en la ruta especificada.`);
        return ''; // Puedes manejar un valor por defecto si no se encuentra la imagen
      }*/
    }
  },
  methods: {
    handleMouseOver() {
      if (window.innerWidth > 992) {
        this.isHovered = true;
      }
    },
    handleMouseLeave() {
      this.isHovered = false;
    },
  },
}
</script>