<template>
  <div>
    <a href="https://wa.me/+56991368503?text=Hola La Higuera!%20Me%20gustaría%20saber%20más%20sobre%20sus%20servicios" target="_blank"><img src="../assets/icons/whatsapp_icon.png" alt="" class="whatsapp-icon"></a>
  </div>
</template>

<script>

export default{
  name: "WhatsAppBtn",
  props: {

  }

}
</script>
