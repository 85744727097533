<template>
  <div>
    <div class="accordion" id="accordionExample">
      <div 
        class="accordion-item"
        data-aos="zoom-in"
        data-aos-offset="100" 
        data-aos-duration="500"
        >
        <h4 class="accordion-header">
          <button class="accordion-button collapsed lato-5-regular white-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUno" aria-expanded="false" aria-controls="collapseUno">
            ¿Cuál es el valor de los servicios de La Higuera?
          </button>
        </h4>
        <div id="collapseUno" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-xxs">
              Cada proyecto tiene requerimientos específicos y un servicio hecho a la medida. Mientras los proyectos de colectas digitales tienen menor costo, también modelamos campañas a medida con equipos físicos. Cotiza tu proyecto a <a href="mailto: hola@lahiguera.org" class="salmon-font">hola@lahiguera.org</a>.
            </p>
          </div>
        </div>
      </div>
      <div 
        class="accordion-item"
        data-aos="zoom-in"
        data-aos-offset="120" 
        data-aos-duration="500"
      >
        <h4 class="accordion-header">
          <button class="accordion-button collapsed lato-5-regular white-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDos" aria-expanded="false" aria-controls="collapseDos">
            ¿Cómo puedo contratar los servicios?
          </button>
        </h4>
        <div id="collapseDos" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-xxs">
              Puedes agendar una reunión sin costo escibiéndonos a <a href="mailto: hola@lahiguera.org" class="salmon-font">hola@lahiguera.org</a> o escribirnos en el formulario de contacto incorporado en esta página.
            </p>
          </div>
        </div>
      </div>
      <div 
        class="accordion-item"
        data-aos="zoom-in"
        data-aos-offset="140" 
        data-aos-duration="500"
      >
        <h4 class="accordion-header">
          <button class="accordion-button collapsed lato-5-regular white-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTres" aria-expanded="false" aria-controls="collapseTres">
            ¿Qué métodos de pagos digitales se pueden contratar? 
          </button>
        </h4>
        <div id="collapseTres" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-xxs">
              Estamos habilitados para operar integrando links de pago online, códigos QR o estaciones de pago físicas.
            </p>
          </div>
        </div>
      </div>
      <div 
        class="accordion-item"
        data-aos="zoom-in"
        data-aos-offset="160" 
        data-aos-duration="500"
      >
        <h4 class="accordion-header">
          <button class="accordion-button collapsed lato-5-regular white-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCuatro" aria-expanded="false" aria-controls="collapseCuatro">
            ¿Cómo realizan la captación de socios? 
          </button>
        </h4>
        <div id="collapseCuatro" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body text-start">
            <p class="text-xxs">
              Hacemos campañas de incorporación de socios a través de mandatos de pago PAT, generando un descuento mensual en la tarjeta de crédito de los donantes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcordionDudas"
};
</script>

