<template>
  <footer>
      <div class="container">
        <div class="d-flex justify-content-between">
          <div>
            <img src="../assets/logos/logo-footer.svg" alt="" class="logo-footer">
          </div>
          <div class="rrss">
            <a href=""><img src="../assets/icons/insta_icon.svg" alt=""></a>
            <a href="https://www.linkedin.com/company/la-higuera-fundraising"><img src="../assets/icons/linkedin_icon.svg" alt=""></a>
          </div>
        </div>
        <hr class="white-font">
        <p class="text-xxs white-font">Diseñado y Desarrollo por <a href="https://vanilla.cl" target="_blank">Vanilla Diseño</a></p>
      </div>
    </footer>
</template>

<script>


export default {
  name: 'FooterComp',
}
</script>