<template>
  <div class="shadow-box">
    <div class="container">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/"><img src="../assets/logos/logo-navbar.svg" alt="logo la higuera"></router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-lg-end justify-content-center" id="navbarNav">
            <ul class="navbar-nav text-center text-xs">
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'beneficios'}">¿Qué es La Higuera?</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'nosotros'}">Nosotros</router-link>
                
              </li>
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'servicios'}">Nuestros Servicios</router-link>
                
              </li>
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'faqs'}">Preguntas Frecuentes</router-link>
              </li>
              <li class="my-lg-0 my-3">
                <LhButton :colorBtn="'salmon-btn'" :buttonText="'¡Contáctanos!'" :router="'/contacto'"/>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

import LhButton from './LhButton.vue'

export default {
  name: 'NavBar',
  components: {
    LhButton,
  }
}
</script>