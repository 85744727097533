<template>
  <div class="home">
    <header class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-12 text-lg-start header-title">
            <h1 class="staat-1">Innovación para tus donaciones</h1>
            <p class="text-m">Recauda más, <span class="salmon-font">reduce costos y mejora la experiencia</span>  para tus donantes</p>
            <LhButton :colorBtn="'salmon-btn'" :buttonText="'¡Contáctanos!'" :router="'/contacto'"/>
          </div>
          <div class="col-lg-6 col-12 hero-cont">
            <img src="../assets/img/hero-img.png" alt="" class="hero-img img-fluid">
          </div>
        </div>
      </div>
    </header>
    <div id="beneficios">
      <div class="container">
        <CarruselBeneficios class="d-lg-none"/>
        <div class="row d-lg-flex d-none">
          <div class="col-lg-3 col-sm-6">
            <CardServicio
              :cardImgHoverRoute="'money-icon-hover.svg'"
              :cardImgRoute="'money-icon.svg'"
              :CardTitle="'Recaudamos por ti'"
              :CardText="'Realizamos campañas de captación físicas y digitales para organizaciones sin fines de lucro, diseñadas a la medida y con atractivo para el público objetivo.'"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine" 
              data-aos-offset="100" 
              data-aos-duration="700"
              />
          </div>
          <div class="col-lg-3 col-sm-6 mt-5">
            <CardServicio
              :cardImgHoverRoute="'heart-icon-hover.svg'"
              :cardImgRoute="'heart-icon.svg'"
              :CardTitle="'Mejoramos la experiencia'"
              :CardText="'Porque los detalles importan, mejoramos la experiencia del donante a través de métodos de pago digitales pero sencillos.'" 
              data-aos="fade-up"
              data-aos-easing="ease-in-sine" 
              data-aos-offset="200" 
              data-aos-duration="700"
            />
          </div>
          <div class="col-lg-3 col-sm-6">
            <CardServicio
              :cardImgHoverRoute="'report-icon-hover.svg'"
              :cardImgRoute="'report-icon.svg'"
              :CardTitle="'Reportamos simple y claro'"
              :CardText="'Tenemos un sistema sencillo de reportería para ordenar la información de recaudaciones, y presentar resultados con claridad.'"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine" 
              data-aos-offset="300" 
              data-aos-duration="700"
              />
          </div>
          <div class="col-lg-3 col-sm-6 mt-5">
            <CardServicio
              :cardImgHoverRoute="'design-icon-hover.svg'"
              :cardImgRoute="'design-icon.svg'"
              :CardTitle="'Diseñamos a tu medida'"
              :CardText="'Queremos optimizar la recaudación a través de hardware modelado a la medida, mejorando la experiencia.'"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine" 
              data-aos-offset="300" 
              data-aos-duration="700"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="nosotros" class="container-fluid">
      <div class="leave-cont">
        <img src="../assets/svg/nosotros-leaves.svg" alt="">
      </div>
      <div class="container">
        <div class="row">
          <div 
            class="col-xl-6 img-cont order-xl-0 order-1"
            data-aos="fade-right"
            data-aos-easing="ease-in-sine" 
            data-aos-offset="300" 
            data-aos-duration="500"
          >
            <img src="../assets/img/nosotros-img.png" alt="" class="img-fluid nosotros-img">
          </div>
          <div 
            class="col-xl-6 text-xl-start nosotros-text order-xl-1 order-0"
            data-aos="fade-left"
            data-aos-easing="ease-in-sine" 
            data-aos-offset="300" 
            data-aos-duration="500"
            >
            <h2 class="staat-2 pb-3">Algo sobre nosotros</h2>
            <p class="lato-5">
              Somos profesionales con <span class="salmon-font">experiencia en ONGs</span>, donde hemos dirigido campañas exitosas de fundraising y crowfunding.
            </p>
            <p>
              La Higuera nace para crear una cultura de donaciones mediante innovación. <span class="salmon-font">Nuestra pasión es apoyar organizaciones para que puedan concentrarse</span> en su labor fundamental y dejarnos el resto a nosotros.
            </p>
            <p>
              En un mundo cada vez más digital y con menor uso de efectivo, estamos convencidos de que innovar aquí es posible, y que <span class="salmon-font">la tecnología es una herramienta clave para aumentar las recaudaciones</span> con eficiencia, innovación y transparencia.
            </p>
            <p class="lato-3 pt-3 pb-2">¿Te gustaría conocernos?</p>
            <LhButton :colorBtn="'salmon-btn'" :buttonText="'¡Escríbenos!'" :router="'/contacto'"/>
          </div>
        </div>
      </div>
    </div>
    <div id="servicios" class="container-fluid">
      <div class="leave-cont big-leave-1">
        <img src="../assets/svg/big-leave-1.svg" alt="" class="img-fluid">
      </div>
      <div class="leave-cont big-leave-2">
        <img src="../assets/svg/big-leave-2.svg" alt="" class="img-fluid">
      </div>
      <div class="leave-cont big-leave-3">
        <img src="../assets/svg/big-leave-3.svg" alt="" class="img-fluid">
      </div>
      <div class="container">
        <h2 class="staat-2 text-center">Nuestros servicios</h2>
        <div 
          class="row"
          data-aos="fade-right"
          data-aos-easing="ease-in-sine" 
          data-aos-offset="300" 
          data-aos-duration="500"
          >
          <div class="col-lg-6 text-lg-end">
            <img src="../assets/img/servicio-1.png" alt="" class="img-fluid">
          </div>
          <div class="col-lg-6 text-lg-start align-content-center">
            <h3 class="staat-2-a">Campañas de recaudación físicas</h3>
            <p class="text-xxs">
              Armamos tus campañas de recolección de fondos, sea para donaciones de una vez o para la incorporación de socios mediante suscripción de mandatos PAT.
            </p>
          </div>
        </div>
        <div 
          class="row"
          data-aos="fade-left"
          data-aos-easing="ease-in-sine" 
          data-aos-offset="300" 
          data-aos-duration="500"
        >
          <div class="col-lg-6 text-lg-end order-lg-1">
            <img src="../assets/img/servicio-2.png" alt="" class="img-fluid">
          </div>
          <div class="col-lg-6 text-lg-start align-content-center order-lg-0">
            <h3 class="staat-2-a">Campañas de recaudación online</h3>
            <p class="text-xxs">
              Integramos métodos de pagos digitales para levantar recursos mediante páginas web, códigos QR, links de pago, entre otros.
            </p>
          </div>
        </div>
        <div 
          class="row"
          data-aos="fade-right"
          data-aos-easing="ease-in-sine" 
          data-aos-offset="300" 
          data-aos-duration="500"
        >
          <div class="col-lg-6 text-lg-end">
            <img src="../assets/img/servicio-3.png" alt="" class="img-fluid">
          </div>
          <div class="col-lg-6 text-lg-start align-content-center">
            <h3 class="staat-2-a">Estaciones de pago</h3>
            <p class="text-xxs">
              Modelamos totems y otras herramientas de hardware para que tus donantes operen de manera autónoma.
            </p>
          </div>
        </div>
        <div 
          class="row"
          data-aos="fade-left"
          data-aos-easing="ease-in-sine" 
          data-aos-offset="300" 
          data-aos-duration="500"
        >
          <div class="col-lg-6 text-lg-end order-lg-1">
            <img src="../assets/img/servicio-4.png" alt="" class="img-fluid">
          </div>
          <div class="col-lg-6 text-lg-start align-content-center order-lg-0">
            <h3 class="staat-2-a">Fidelización de socios</h3>
            <p class="text-xxs">
              Nos comunicamos con tus donantes para mantener la relación activa y asegurar su compromiso en el tiempo. Les hacemos sentir tu gratitud y la importancia de su compromiso.
            </p>
          </div>
        </div>
        <div 
          class="row"
          data-aos="fade-right"
          data-aos-easing="ease-in-sine" 
          data-aos-offset="300" 
          data-aos-duration="500"
        >
          <div class="col-lg-6 text-lg-end">
            <img src="../assets/img/servicio-5.png" alt="" class="img-fluid">
          </div>
          <div class="col-lg-6 text-lg-start align-content-center">
            <h3 class="staat-2-a">Reportería</h3>
            <p class="text-xxs">
              Te entregamos una reportabilidad simple y amigable, para que puedas analizar en fácil cómo van tus donaciones.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div 
      id="cta"
      data-aos="fade-right"
      data-aos-offset="200" 
      data-aos-duration="800"
      >
      <div class="container">
        <div class="col-lg-7 text-lg-start">
          <h2 class="lato-1 white-font">Somos el aliado perfecto para <span class="lato-1-regular">optimizar tus recaudaciones</span></h2>
        </div>
        <div class="col-lg-5 cta-img-cont">
          <img src="../assets/img/cta-img.png" alt="" class="img-fluid cta-img">
        </div>
      </div>
    </div>
    <div id="faqs" class="container">
      <div class="row">
        <div class="col-lg-5 order-lg-0 order-1 hand-img">
          <img src="../assets/img/phone-faqs.png" alt="">
        </div>
        <div class="col-lg-7 text-faqs order-lg-1 order-0">
          <h2 class="staat-2 pb-5">Preguntas Frecuentes</h2>
          <AcordionDudas/>
        </div>
      </div>
    </div>
    <div id="dudas">
      <div class="row">
        <div class="col-lg-3 col-12">
          <img src="../assets/svg/white-leaves.svg" alt="" class="img-fluid">
        </div>
        <div class="col-lg-6 col-12 container">
          <h2 class="staat-2 white-font">¿Aún tienes dudas?</h2>
          <div>
            <LhButton :colorBtn="'purple-btn'" :buttonText="'¡Contáctanos!'" :router="'/contacto'"/>
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <img src="../assets/svg/white-leaves.svg" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LhButton from '../components/LhButton'
import CardServicio from '../components/CardServicio'
import AcordionDudas from '../components/AcordionDudas'
import CarruselBeneficios from '../components/CarruselBeneficios'

export default {
  name: 'HomeView',
  components: {
    CardServicio,
    LhButton,
    AcordionDudas,
    CarruselBeneficios,
  }
}
</script>
