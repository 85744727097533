<template>
  <div id="carouselExampleIndicators" class="carousel slide">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <CardServicio
          :cardImgRoute="'money-icon.svg'"
          :CardTitle="'Recaudamos por ti'"
          :CardText="'Realizamos campañas de captación físicas y digitales para organizaciones sin fines de lucro, diseñadas a la medida y con atractivo para el público objetivo.'"
        />
      </div>
      <div class="carousel-item">
        <CardServicio
          :cardImgRoute="'heart-icon.svg'"
          :CardTitle="'Mejoramos la experiencia'"
          :CardText="'Porque los detalles importan, mejoramos la experiencia del donante a través de métodos de pago digitales pero sencillos.'"
        />
      </div>
      <div class="carousel-item">
        <CardServicio
          :cardImgRoute="'report-icon.svg'"
          :CardTitle="'Reportamos simple y claro'"
          :CardText="'Tenemos un sistema sencillo de reportería para ordenar la información de recaudaciones, y presentar resultados con claridad.'"
        />
      </div>
      <div class="carousel-item">
        <CardServicio
          :cardImgRoute="'design-icon.svg'"
          :CardTitle="'Diseñamos a tu medida'"
          :CardText="'Queremos optimizar la recaudación a través de hardware modelado a la medida, mejorando la experiencia.'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardServicio from './CardServicio'

export default {
  name: 'CarruselBeneficios',
  components: {
    CardServicio,
  },
  props: {
    router: {
      type: Object,
      default() {
        return { message: 'hello' }
      }
    },
    buttonText: {
      type: String,
      default: 'Button'
    },
    colorBtn: {
      type: String,
      default: 'pink-btn'
    },
  }
}
</script>