<template>
  <WhatsAppBtn class="whatsapp fixed-button" :v-if="showButton"/>
  <NavBar/>
  <router-view/>
  <FooterComp/>
</template>

<script>
import NavBar from './components/NavBar'
import FooterComp from './components/FooterComp'
import WhatsAppBtn from './components/WhatsAppBtn'

export default {
  name: 'App',
  components: {
    NavBar,
    FooterComp,
    WhatsAppBtn
  }
}
</script>


